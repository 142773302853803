import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Riedl, H., Croft, B.A., and Howitt, A.J. 1976. Forecasting codling moth
        physiology based on pheromone trap catches and physiological-time
        models. Can. Entomol. 108: 449-460.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Rock, G.C., Stinner, R.E., Bacheler, J.E., Hull, L.A., and Hogmire, Jr.,
        H.W. 1993. Predicting geographical and within-season variation in male
        flights of four fruit pests. Environmental Entomology. 22: 716-725.
      </p>
    </>
  )
}
